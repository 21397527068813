<template>
  <div>
    <Loading :loading="loading" />

    <v-row
      v-for="(cat, i) in categories"
      :key="i"
    >
      <v-col>
        <v-card>
          <v-card-title>
            {{ cat.title }}
          </v-card-title>
          <v-card-text class="text-body-2">
            <v-data-table
              :headers="headers"
              :items="cat.persons"
              :items-per-page="-1"
              hide-default-footer
            >
              <template #[`item.cellPhone`]="{ item }">
                {{ item.cellPhone ? item.cellPhone : item.phone | phoneNumber }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

export default {  
  name: 'Organization',
  components: {
    Loading
  },
  data() {
    return {
      categories: [],
      loading: false,
      headers: [
        { text: 'Navn', value: 'fullName', width: '25%' },
        { text: 'Titel', value: 'title', width: '20%' },
        { text: 'Rolle', value: 'roleTitle', width: '25%' },
        { text: 'Telefon', value: 'cellPhone', width: 200 },
        { text: 'E-mail', value: 'email', width: '20%' }
      ]
    }
  },
  async created() {
    this.loading = true;

    try {
      const response = await this.$AuthService.api.get('organization');
      this.categories = response.data?.data?.categories;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>